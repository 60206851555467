<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="text-center">{{ $t('home.orders.overview') }}</h2>
      </v-col>

      <v-col cols="12" sm="6" class="flex-grow">
        <v-card class="height: 100%;" outlined>
          <v-card-title>
            {{ $t('home.orders.total') }}
          </v-card-title>

          <v-card-text class="text-center">
            <h2>{{ $tc('home.orders.helper', monitorInfo.total, { count: monitorInfo.total }) }}</h2>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" class="flex-grow">
        <v-card class="height: 100%;" outlined>
          <v-card-title class="d-flex justify-space-between">
            {{ $t('home.units.total') }}

            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  small
                  icon
                  :disabled="isLoading"
                  :loading="isLoading"
                  color="primary"
                  v-on="on"
                  @click="emitReport"
                >
                  <v-icon small>mdi-car-connected</v-icon>
                </v-btn>
              </template>

              <span>{{ $t('reports.reportStatus') }}</span>
            </v-tooltip>
          </v-card-title>

          <v-card-text class="text-center">
            <h2>{{ $tc('home.units.helper', monitorInfo.units, { count: monitorInfo.units }) }}</h2>
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col cols="12" sm="6" class="flex-grow">
        <v-card class="height: 100%;" outlined>
          <v-card-title>
            {{ $t('home.orders.delivered') }}
          </v-card-title>

          <v-card-text class="text-center">
            <h2>{{ $tc('home.orders.helper', monitorInfo.delivered, { count: monitorInfo.delivered }) }}</h2>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" class="flex-grow">
        <v-card class="height: 100%;" outlined>
          <v-card-title>
            {{ $t('home.orders.cancelled') }}
          </v-card-title>

          <v-card-text class="text-center">
            <h2>{{ $tc('home.orders.helper', monitorInfo.cancelled, { count: monitorInfo.cancelled }) }}</h2>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="4" class="flex-grow">
        <v-card class="height: 100%;" outlined>
          <v-card-title>
            {{ $t('home.orders.pending') }}
          </v-card-title>

          <v-card-text class="text-center">
            <h2>{{ $tc('home.orders.helper', monitorInfo.pending, { count: monitorInfo.pending }) }}</h2>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="4" class="flex-grow">
        <v-card class="height: 100%;" outlined>
          <v-card-title>
            {{ $t('home.orders.loading') }}
          </v-card-title>

          <v-card-text class="text-center">
            <h2>{{ $tc('home.orders.helper', monitorInfo.loading, { count: monitorInfo.loading }) }}</h2>
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col cols="12" sm="6" md="4" class="flex-grow">
        <v-card class="height: 100%;" outlined>
          <v-card-title>
            {{ $t('home.orders.transit') }}
          </v-card-title>

          <v-card-text class="text-center">
            <h2>{{ $tc('home.orders.helper', monitorInfo.transit, { count: monitorInfo.transit }) }}</h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="my-4 mt-8" />

    <v-row>
      <v-col cols="12">
        <h2 class="text-center">{{ $t('home.geofences.diagnostic') }}</h2>
      </v-col>

      <v-col cols="12" sm="6" class="flex-grow">
        <v-card class="height: 100%;" outlined>
          <v-card-title>
            {{ $t('home.plants.without') }}

            <v-btn
              v-if="monitorInfo.plants > 0"
              x-small
              icon
              color="info"
              :to="`/Entities/Plants?filter=notLinked`"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text :class="`text-center ${monitorInfo.plants > 0 ? 'error--text' : 'success--text'}`">
            <h2>{{ $tc('home.geofences.helper', monitorInfo.plants, { count: monitorInfo.plants }) }}</h2>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" class="flex-grow">
        <v-card class="height: 100%;" outlined>
          <v-card-title>
            {{ $t('home.destinations.without') }}

            <v-btn
              v-if="monitorInfo.destinations > 0"
              x-small
              icon
              color="info"
              :to="`/Entities/Destinations?filter=notLinked`"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text :class="`text-center ${monitorInfo.destinations > 0 ? 'error--text' : 'success--text'}`">
            <h2>{{ $tc('home.geofences.helper', monitorInfo.destinations, { count: monitorInfo.destinations }) }}</h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
export default {
  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    orders () { return this.$store.state.orders.list },
    units () { return this.$store.state.units.list },
    plants () { return this.$store.state.plants.list },
    destinations () { return this.$store.state.destinations.list },

    monitorInfo () {
      return {
        total: this.orders.length,
        pending: this.orders.filter(item => item.state === 'PENDING').length,
        cancelled: this.orders.filter(item => item.state === 'CANCELLED').length,
        loading: this.orders.filter(item => item.state === 'LOADING').length,
        transit: this.orders.filter(item => item.state === 'TRANSIT').length,
        delivered: this.orders.filter(item => item.state === 'DELIVERED').length,
        units: this.units.length,
        plants: this.plants.filter(item => !item.geofence?.pk).length,
        destinations: this.destinations.filter(item => !item.geofence?.pk).length
      }
    }
  },

  methods: {
    toBuffer (base64) {
      const binaryString = window.atob(base64)
      const len = binaryString.length
      const bytes = new Uint8Array(len)
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }
      return bytes.buffer
    },

    emitReport () {
      this.isLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation ($apiToken: String!) {
            emitBroadcastReport(apiToken: $apiToken) {
              status
              errors
              result
            }
          }
        `,
        variables: {
          apiToken: this.$store.state.session.apiToken
        }
      }).then((response) => {
        const { status, result } = response.data.emitBroadcastReport

        if (status === 'OK') {
          window.download(this.toBuffer(result), `${this.$t('reports.broadcastFile')}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        } else {
          this.$store.commit('toggleSnackbar')
        }
      }).catch((err) => {
        console.log('[views.Reports] emitReport', err)
        this.$store.commit('toggleSnackbar')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>