<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>{{ $t('reports.title') }}</v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-date-range-picker
                  v-model="range"
                  clearText="Limpiar fechas"
                  okText="Guardar"
                  color="primary"
                  :label="$t('reports.range')"
                  :dialogWidth="800"
                  outlined
                />
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedUnits"
                  :items="units"
                  :label="$t('reports.unitsIds')"
                  item-text="plate"
                  item-value="pk"
                  multiple
                  outlined
                  small-chips
                  deletable-chips
                  :hint="$t('reports.hint')"
                  persistent-hint
                />
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedPlants"
                  :items="plants"
                  :label="$t('reports.plantsIds')"
                  item-text="name"
                  item-value="pk"
                  multiple
                  outlined
                  small-chips
                  deletable-chips
                  :hint="$t('reports.hint')"
                  persistent-hint
                />
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedDestinations"
                  :items="destinations"
                  :label="$t('reports.destinationsIds')"
                  item-text="name"
                  item-value="pk"
                  multiple
                  outlined
                  small-chips
                  deletable-chips
                  :hint="$t('reports.hint')"
                  persistent-hint
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex flex-row-reverse">
            <v-btn
              :loading="isLoading"
              :disabled="isLoading"
              depressed
              color="primary"
              @click="emitReport"
            >
              {{ $t('reports.emit') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DateTimeRange } from 'vuetify-dates-pickers'
import gql from 'graphql-tag'

export default {
  components: {
    'v-date-range-picker': DateTimeRange
  },

  data () {
    return {
      isLoading: false,

      selectedUnits: [],
      selectedPlants: [],
      selectedDestinations: [],
      range: [
        new Date(new Date() - 24 * 60 * 60 * 1000),
        new Date()
      ]
    }
  },

  computed: {
    orders () { return this.$store.state.orders.list },
    units () { return this.$store.state.units.list },
    plants () {
      if (this.selectedUnits.length === 0) {
        return this.$store.state.plants.list
      }

      const plantsIds = [
        ...new Set(this.orders.filter(order => this.selectedUnits.includes(order.unit?.pk)).map(order => order.plant?.pk))
      ]

      return this.$store.state.plants.list.filter(plant => {
        return plantsIds.includes(plant?.pk)
      })
    },
    destinations () {
      if (this.selectedUnits.length === 0) {
        return this.$store.state.destinations.list
      }

      const destinationsIds = [
        ...new Set(this.orders.filter(order => this.selectedUnits.includes(order.unit?.pk)).map(order => order.destination?.pk))
      ]

      return this.$store.state.destinations.list.filter(destination => {
        return destinationsIds.includes(destination?.pk)
      })
    },

    unitsIds: {
      get () {
        return this.selectedUnits.map(item => {
          return {
            id: item,
            name: this.units.find(obj => obj.pk === item)?.plate || 'N/A'
          }
        })
      },

      set (value) {
        this.selectedUnits = value.map(item => item.pk)
      }
    },
    plantsIds: {
      get () {
        return this.selectedPlants.map(item => {
          return {
            id: item,
            name: this.plants.find(obj => obj.pk === item)?.name || 'N/A'
          }
        })
      },

      set (value) {
        this.selectedPlants = value.map(item => item.pk)
      }
    },
    destinationsIds: {
      get () {
        return this.selectedDestinations.map(item => {
          return {
            id: item,
            name: this.destinations.find(obj => obj.pk === item)?.name || 'N/A'
          }
        })
      },

      set (value) {
        this.selectedDestinations = value.map(item => item.pk)
      }
    }
  },

  methods: {
    toBuffer (base64) {
      const binaryString = window.atob(base64)
      const len = binaryString.length
      const bytes = new Uint8Array(len)
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }
      return bytes.buffer
    },

    emitReport () {
      this.isLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation ($apiToken: String!, $unitsIds: [ID], $plantsIds: [ID], $destinationsIds: [ID], $startAt: Unix!, $endAt: Unix!) {
            emitReport(
              apiToken: $apiToken,
              unitsIds: $unitsIds,
              plantsIds: $plantsIds,
              destinationsIds: $destinationsIds,
              startAt: $startAt,
              endAt: $endAt
            ) {
              status
              errors
              result
            }
          }
        `,
        variables: {
          apiToken: this.$store.state.session.apiToken,
          unitsIds: this.selectedUnits,
          plantsIds: this.selectedPlants,
          destinationsIds: this.selectedDestinations,
          startAt: this.range[0] / 1000,
          endAt: this.range[1] / 1000
        }
      }).then((response) => {
        const { status, result } = response.data.emitReport

        if (status === 'OK') {
          window.download(this.toBuffer(result), `${this.$t('reports.file')}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        } else {
          this.$store.commit('toggleSnackbar')
        }
      }).catch((err) => {
        console.log('[views.Reports] emitReport', err)
        this.$store.commit('toggleSnackbar')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>